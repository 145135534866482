import React from "react"
import { Link, graphql } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

import "react-lazy-load-image-component/src/effects/blur.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/swiper/heroswiper"

import ContactForm from "../components/contact-form"

import threesixty from "../images/360-icon.png"
import pulse from "../images/pulse-icon.png"
import leader from "../images/leader-icon.png"
import assesment from "../images/360-service.png"
import culture from "../images/CulturePulse.png"
import leaderist from "../images/Leadervest.png"
import inventimg from "../images/invention-magazine.png"
import inventpdf from "../../static/assets/Invention-Winter-2020.pdf"
import prismimg from "../images/prism-award.png"
import tribe from "../images/create-a-tribe.png"
import hug from "../images/build-trust.png"
import confident from "../images/work-confidently.png"

class IndexPage extends React.Component {
  render() {
    const posts = this.props.data.allSanityPost.edges
    console.log(posts)
    return (
      <Layout
        location={this.props.location}
        title="Leadership Development Coaching - Executive Life Coaching"
      >
        <SEO
          title="Leadership Development Coaching - Executive Life Coaching"
          description="Did you know 1 disengaged employee costs you $16,000 a year. AGL's leadership development & executive life coaching will help you bring your team together. Learn how today!"
        />
        <div id="hero" className="hero-section bg-light">
          <div className="container-fluid p-0">
            <Hero>
              <div>
                <p className="d-none" />
              </div>
            </Hero>
            <div className="hero-content text-center">
              <h1 className="herotitle" style={{ color: `#434343` }}>
                AGL Coaching for Good – Leader Development Coaching
              </h1>
              <h3 className="mb-4">
                Our values coaching increases revenue by 119% and workplace
                satisfaction by 25%.{" "}
                <Link to="/contact-us">Contact us to learn more.</Link>
              </h3>{" "}
              <h3 className="herop mb-4" style={{ color: `#434343` }}>
                Disengagement prevents teams from being "all in" and regardless
                of your organization’s size it's likely costing you thousands
                because 65% of employees are not engaged at work.
              </h3>
              <h3 className="herop mb-6" style={{ color: `#434343` }}>
                Disengaged employees have 18% lower productivity, 15% lower
                profitability, and 37% higher absenteeism.
              </h3>
              <a href="tel:7198002149">
                <span className="hero-btn align-items-center sched-modal">
                  <svg
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 512.001 512.001"
                    className="mr-3 mb-2"
                  >
                    <path
                      fill="#fff"
                      d="M498.808 377.784l-63.633-63.647c-16.978-16.978-46.641-17.007-63.647 0l-10.611 10.611 127.284 127.277 10.607-10.607c17.619-17.62 17.56-46.104 0-63.634zM339.116 345.37c-13.39 10.373-32.492 9.959-44.727-2.303L168.572 217.163c-12.263-12.263-12.676-31.379-2.303-44.736L39.278 45.443c-54.631 63.68-52.495 159.633 7.8 219.928l199.103 199.19c57.86 57.858 152.635 65.532 219.932 7.797L339.116 345.37zM197.503 76.391L133.87 12.744c-16.978-16.978-46.641-17.007-63.647 0L59.612 23.355l127.284 127.277 10.607-10.608c17.618-17.618 17.56-46.102 0-63.633z"
                    />
                  </svg>
                  Get Leadership Development Coaching
                </span>
              </a>
            </div>
          </div>
          <section id="services-list">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="engager text-center ">
                    <a
                      className="services-list-engager d-flex align-items-center justify-content-center"
                      href="#services"
                    >
                      <LazyLoadImage
                        className="engager-icon mr-3"
                        src={threesixty}
                        alt="Engager Icon"
                      />
                      <h4 className="mt-2 mb-0 text-white">360 Assessments</h4>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="engager text-center">
                    <a
                      className="services-list-culture d-flex align-items-center justify-content-center"
                      href="#services"
                    >
                      <LazyLoadImage
                        className="pulse mr-3"
                        src={pulse}
                        alt="Pulse Icon"
                      />
                      <h4 className="mt-2 mb-0 text-white">Culture Pulse</h4>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="engager text-center">
                    <a
                      className="services-list-leaderist d-flex align-items-center justify-content-center"
                      href="#services"
                    >
                      <LazyLoadImage
                        className="leader-icon mr-3"
                        src={leader}
                        alt="Leader Icon"
                      />
                      <h4 className="mt-2 mb-0 text-white">Leadervest</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h3 className="mb-4 text-center">
                     On average 65% of employees are disengaged at work and even
                    one disengaged employee can cost your organization $16,000 a
                    year and up, depending on their position.
                </h3>
                  <p className="mb-4" style={{ color: `#434343` }}>This fact, coupled with the ever-increasing
                  turnover rate, calls for a workplace culture that makes
                  employees wake up wanting to come into work rather than
                  dreading it and leave feeling appreciated and fulfilled.
                  Achieving such a culture starts at the top with those in
                  leadership positions and leadership development coaching gives
                  them the tools to create, nurture, and maintain a great work
                  environment.
                 </p>
                 <p className="mb-4" style={{ color: `#434343` }}>
                  If your answer is “yes” to any of the questions below, you and
                  your organization will benefit from our leadership development
                  coaching and executive life coaching services:
                </p>
                <div className="about-content">
                  <div className="row">
                    <div className="col">
                      <ul className="checks-ul">
                        <li className="list-checks my-3 herop" style={{ color: `#434343` }}>
                          Do you feel comfortable having difficult conversations
                          with your team or do you dread having those “hard
                          conversations?
                        </li>
                        <li className="list-checks my-3 herop" style={{ color: `#434343` }}>
                          Are you confident in your leadership, or do you wonder
                          if your team respects you?
                        </li>
                        <li className="list-checks my-3 herop" style={{ color: `#434343` }}>
                          Do you trust your team to get things done, or do you
                          find yourself micromanaging others to meet deadlines?
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="checks-ul">
                        <li className="list-checks my-3 herop" style={{ color: `#434343` }}>
                          Does your team communicate well and work effectively
                          together, or are you concerned about what happens when
                          you’re not there?
                        </li>
                        <li className="list-checks my-3 herop" style={{ color: `#434343` }}>
                          Are you excited to wake up and head into wok each day,
                          or do you watch the clock waiting to go home?
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 mx-auto text-center">
                      <p>
                        With our leadership development coaching and executive
                        life coaching services, you’ll get the tools you need to
                        become a confident team leader and foster a positive and
                        engaging workplace environment.
                      </p>
                      <p>
                        Especially for nonprofits and those in the healthcare
                        field, there a variety of benefits to be had. Through
                        developing your strengths as a leader with our executive
                        life coaching efforts, you’ll turn your employees to
                        enthusiastic and dedicated members of the team and the
                        office into a place they look forward to coming in to.
                        Additionally, you’ll be developing a culture with high
                        employee morale where everyone feels valued, and risks
                        get rewarded. Start building this thriving culture of
                        engagement today with our executive life coaching!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-5 text-center">
                  <Link to="/#get-coaching">
                    <span className="hero-btn align-items-center sched-modal">
                      Get Coaching
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5">
              <div className="col-12 mx-auto">
                <div className="row text-center">
                  <div className="col mx-4">
                    <img className="w-50" src={tribe} alt="Create a Tribe" />
                    <h3 className="mt-5 mb-3">Create a Tribe</h3>
                    <h5 className="em-gray">
                      Transform your employees into an enthusiastic and
                      committed team.
                    </h5>
                  </div>
                  <div className="col mx-4">
                    <img className="w-50" src={hug} alt="Build Trust" />
                    <h3 className="mt-5 mb-3">Build Trust</h3>
                    <h5 className="em-gray">
                      Develop a culture where creativity is encouraged and risks
                      are rewarded.
                    </h5>
                  </div>
                  <div className="col mx-4">
                    <img
                      className="w-50"
                      src={confident}
                      alt="Work Confidently"
                    />
                    <h3 className="mt-5 mb-3">Work Confidently</h3>
                    <h5 className="em-gray">
                      Discover how your strengths make you a better leader.
                    </h5>
                  </div>
                </div>
                <div className="my-5 text-center">
                  <Link to="/#get-coaching">
                    <span className="hero-btn align-items-center sched-modal">
                      Get Coaching
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="services-content text-center mb-5">
                  <h2 className="section-title">How We Work</h2>
                </div>
              </div>

              <div className="col-md-4 mb-4">
                <Link to="/360review">
                  <div className="service-list text-center h-100">
                    <div className="service-image mb-5">
                      <LazyLoadImage
                        className="img-fluid"
                        src={assesment}
                        alt="Engager"
                      />
                    </div>
                    <h3 className="mb-3">360 Assessments</h3>
                    <p>
                      Receive groundbreaking feedback in the 15 most important
                      competencies for today's global leader.
                    </p>
                    <div className="price">
                      <h4 className="mb-0">Starting At</h4>
                      <h2>$997</h2>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 mb-4">
                <Link to="/culture-pulse">
                  <div className="service-list text-center h-100">
                    <div className="service-image mb-5">
                      <LazyLoadImage
                        className="img-fluid"
                        src={culture}
                        alt="Culture Pulse"
                      />
                    </div>
                    <h3 className="mb-3">Culture Pulse</h3>
                    <p>
                      Learn 10 proven tools to build a better team in two days
                      with leadership development coaching.
                    </p>
                    <div className="price">
                      <h4 className="mb-0">Starting At</h4>
                      <h2>$4997</h2>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 mb-4">
                <Link to="/leadervest">
                  <div className="service-list text-center h-100">
                    <div className="service-image mb-5">
                      <LazyLoadImage
                        className="img-fluid"
                        src={leaderist}
                        alt="Leaderist"
                      />
                    </div>
                    <h3 className="mb-3">Leadervest</h3>
                    <p>The ultimate year-long leadership experience</p>
                    <div className="price leaderist-price">
                      <h2>Custom Pricing</h2>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="my-5 text-center">
              <Link to="/#get-coaching">
                <span className="hero-btn align-items-center sched-modal">
                  Get Coaching
                </span>
              </Link>
            </div>
          </div>
        </section>
        <section id="casestudy">
          <div className="container">
            <div className="row ">
              <div className="col-md-6 mx-auto mb-3">
                <div className="casestudy-content text-center">
                  <h4 className=" mb-5 text-gold text-uppercase">
                    SEE OUR FEATURED ARTICLE IN INVENTION MAGAZINE ON FINDING
                    PURPOSE THROUGH COACHING
                  </h4>

                  <p className="section-p mb-6 mx-auto">
                    <a
                      href={inventpdf}
                      alt="Invention Magazine"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="w-50"
                        src={inventimg}
                        alt="Invention Mag"
                      />
                    </a>
                  </p>
                  <a
                    className="hero-btn align-items-center sched-modal"
                    href={inventpdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-md-6 mx-auto mb-3">
                <div className="casestudy-content text-center">
                  <h4 className="mb-5 text-gold text-uppercase">
                    WINNERS OF THE 2020 SOUTHERN COLORADO SOCIAL IMPACT STARTUP
                    OF THE YEAR PRISM AWARD
                  </h4>

                  <p className="section-p mb-6 mx-auto">
                    <a
                      href="https://www.ci4si.org/prism-awards-2020/"
                      alt="Invention Magazine"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="w-50"
                        src={prismimg}
                        alt="Invention Mag"
                      />
                    </a>
                  </p>
                  <a
                    className="hero-btn align-items-center sched-modal"
                    href="https://www.ci4si.org/prism-awards-2020/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonial">
          <div className="container">
            <h3 className="section-subtitle text-center">Testimonials</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="testimonial-video">
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/Mbc9ynVPqY4?rel=0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Testimonial Video"
                  />
                </div>
                <h4 className="text-center mt-3">
                  Zac talks about how coaching helped him move from starting
                  things to finishing them.
                </h4>
              </div>
              <div className="col-md-6">
                <div className="testimonial-video">
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/W0nXxEkLrIs"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="Testimonial"
                  />
                </div>
                <h4 className="text-center mt-3">
                  Valerie talks about the impact Culture Pulse had on her team
                  and her organization.
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section id="get-coaching">
          <div className="container">
            <h3 className="section-subtitle text-center">Get Coaching</h3>
            <h4 className="text-center">
              <em>Schedule Your Complimentary Coaching Session Now!</em>
            </h4>
            <div className="row">
              <div className="col-md-8 mx-auto">
                <ContactForm />
                {/*} <iframe
                  src="https://calendly.com/coachingforgood/30min?month=2020-03"
                  width="100%"
                  height="650px"
                  frameborder="0"
                  title="Calendly"
                  style={{
                    overflow: "hidden !important",
                  }}
                /> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
